import pcBadgeImage from 'assets/Premium_Credit_Ltd_Logo.png';

const PremiumCreditBadge: React.FunctionComponent = function ({}) {
  return (
    <div className="w-30 min-w-30 -mt-2">
      <img src={pcBadgeImage} alt="premium-credit" />
    </div>
  );
};

export default PremiumCreditBadge;
