import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { URLS } from 'config';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect } from 'react';
import BulletListSidebar from '../../../../../../../../../components/BulletListSidebar';

interface ViewComponentProps {
  sidebarTitle?: string;
  sidebarBullets?: {
    title: string;
    subText: string;
  }[];
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({
  sidebarTitle = '',
  sidebarBullets = [],
  children,
}: ViewComponentProps) => {
  const { currentStepIndex } = useOnboardingContextValue();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'company-info');
    if (currentStepIndex === 0) {
      window.history.pushState({}, '', `${url}?step=trade-info`);
      logEvent('page-view');
      return;
    }
    if (currentStepIndex === 1) {
      window.history.pushState({}, '', `${url}?step=job-info`);
      logEvent('page-view');
      return;
    }
    if (currentStepIndex === 2) {
      window.history.pushState({}, '', `${url}?step=online-presence-info`);
      logEvent('page-view');
      return;
    }
    if (currentStepIndex === 3) {
      window.history.pushState({}, '', `${url}?step=insurance-info`);
      logEvent('page-view');
      return;
    }
    window.history.pushState(
      {},
      '',
      `${url}?step=terms-and-conditions-contract`,
    );
    logEvent('page-view');
  }, [currentStepIndex, logEvent]);

  return (
    <div className="flex flex-col md:flex-1 px-6 mx-auto md:mt-0 md:flex-row md:p-16 lg:p-20 md:max-w-256 -mt-4 md:mt-0">
      <BulletListSidebar title={sidebarTitle} bullets={sidebarBullets} />
      <div className="flex flex-col w-full h-full md:w-7/12 max-w-120 mx-auto">
        {children}
      </div>
    </div>
  );
};

ViewComponent.displayName = 'Info-view';

ViewComponent.defaultProps = {
  sidebarTitle: 'Main title',
  sidebarBullets: [
    {
      title: 'title',
      subText: 'subtext',
    },
  ],
  children: null,
};

export default ViewComponent;
