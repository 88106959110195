import { Widget } from '@kanda-libs/ks-component-ts';
import { Button, Loader, Text } from '@kanda-libs/ks-design-library';
import banner from 'assets/kingsbridge-banner.png';

import { INSURANCE_DOCUMENT_PROPS } from './Form-constants';
import Container from './Form-container';

interface ViewComponentProps {
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const ViewComponent = ({ isLoading, isSubmitting }: ViewComponentProps) => (
  <Container>
    {({ inputFile, fileError, showExpiry, validation }) => (
      <>
        <div className="relative w-full rounded-xl overflow-hidden mb-6 -mt-8 md:-mt-0">
          <img src={banner} alt="kingsbridge-banner" />
          <a
            className="absolute bottom-0 left-0"
            href="https://kanda.kingsbridge.co.uk/"
            aria-label="kingsbridge-link"
          >
            <div className="w-30 h-10" />
          </a>
        </div>
        <Text
          text="Public Liability Insurance"
          className="text-14-22-em text-neutral-800 font-bold mb-2"
        />
        <div className="flex flex-col">
          <div className="flex flex-col order-3 md:order-1 mt-6 md:mt-0">
            <span className="text-12-18 md:text-14-22 mb-2">
              <span className="text-12-18-em md:text-14-22-em">
                Don&apos;t have insurance?
              </span>{' '}
              Our insurance partner, Kingsbridge, offer{' '}
              <span className="text-12-18-em md:text-14-22-em">
                10% discount
              </span>{' '}
              to Kanda customers on their Public Liability package, which
              includes tools cover at no extra cost!
            </span>
            <span className="text-12-18 md:text-14-22 mb-4">
              <a
                className="text-12-18-em md:text-14-22-em underline"
                href="https://kanda.kingsbridge.co.uk/"
                aria-label="kingsbridge-link"
              >
                Click here
              </a>{' '}
              to start a quote, call{' '}
              <a
                className="text-12-18-em md:text-14-22-em underline"
                href="tel:01242808744"
                aria-label="kingsbridge-link"
              >
                01242 808744
              </a>{' '}
              and quote{' '}
              <span className="text-12-18-em md:text-14-22-em">
                &apos;Kanda&apos;
              </span>{' '}
              or email{' '}
              <a
                className="text-12-18-em md:text-14-22-em underline"
                href="mailto:dan.prior@kingsbridge.co.uk"
                aria-label="kingsbridge-link"
              >
                dan.prior@kingsbridge.co.uk
              </a>
            </span>
          </div>
          <div className="flex flex-col order-2">
            <Widget.CompanyCompanyInfoInsuranceDocumentContent
              {...INSURANCE_DOCUMENT_PROPS}
              error={fileError}
              inputFile={inputFile}
            />

            {showExpiry && (
              <Widget.CompanyCompanyInfoInsuranceDocumentExpiry
                validation={validation}
              />
            )}
            <Button.Text
              submit
              id="onboarding-insurance-doc-submit"
              label="Submit document"
              icon="arrow-right"
              className="w-full"
              isLoading={isLoading}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <Loader isLoading={isSubmitting} />
      </>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  isSubmitting: false,
};

ViewComponent.displayName = 'InsuranceInfoForm-view';

export default ViewComponent;
